import { useEffect, useMemo, useRef, useState } from 'react';

import { LogEvent } from '@analytics/LogEvent/LogEvent';
import { BaseLessonCode, useGetUser } from '@db/collections';
import { isUserManager } from '@db/collections/User/utils';
import { BOXBUTTON_COLOR, BoxButton, Label, Rating } from 'design-system';
import Image from 'next/image';
import { useRouter } from 'next/router';
import posthog from 'posthog-js';

import AdvertisementInfoTooltip from '@components/Advertisement/AdvertisementInfoTooltip';
import useGetAdvertisementLocation from '@components/Advertisement/get-advertisement-location';
import { useGetAdvertisementInfoByLocation } from '@components/Advertisement/queries';

import { createAdLogLabel } from '@hooks/use-create-ad-log-label';
import { useGetLicenseByFunnel } from '@hooks/use-get-license-by-funnel';
import { useMatchProductWithAcademyProductList } from '@hooks/use-match-product-with-academy-productlist';

import AdvertisementLabel from './components/AdvertisementLabel';

export interface UserReviewType {
  reviewId: string;
  userName: string;
  averageRate: number;
  lessonCode: LessonCode;
  lectureProgress: string;
  detailedScores: {
    facility: number;
    lecture: number;
    service: number;
  };
  contents: {
    images: string[];
    description: string;
  };
}

export interface AdvertisementDataType {
  location: string; // 요청 들어온 위치 ( 00시 00구 00동 )
  academy: {
    academyId: string;
    academyName: string;
    tags: {
      advantages: string[];
      mainTag: string;
    };
    location: string;
    products: {
      totalPrice: number;
      type: string;
    }[];
    reviews: {
      totalRating: number;
      totalParticipants: number;
      userReviews: UserReviewType[];
    };
    image: string;
  };
}

const prod = process.env.NEXT_PUBLIC_PLATFORM_ENV === 'production';

export default function HomeAdvertisement() {
  const latestLocation = useGetAdvertisementLocation();

  const { data: adData } = useGetAdvertisementInfoByLocation({
    lat: latestLocation?.lat || null,
    lng: latestLocation?.lng || null,
  });

  const { user } = useGetUser();

  const userPersonalLicense = useGetLicenseByFunnel();

  const matchedProduct = useMatchProductWithAcademyProductList(
    adData?.academy.products ?? [],
    userPersonalLicense?.lessonType ?? null,
  );

  const mainProduct = useMemo(() => {
    return adData?.academy.products && matchedProduct;
  }, [matchedProduct, adData?.academy.products]);

  const router = useRouter();

  const targetRef = useRef(null);
  const [hasBeenVisible, setHasBeenVisible] = useState(false);

  useEffect(() => {
    const observerCallback = (entries: any[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && entry.intersectionRatio >= 0.75 && !hasBeenVisible) {
          if (!adData) return;

          LogEvent.활성화.GAEvent(
            'impression_academyAd_home',
            createAdLogLabel({ adData: adData, userId: user?.uid || '' }),
          );
          posthog.capture(
            'impression_academyAd_home',
            createAdLogLabel({ adData: adData, userId: user?.uid || '' }),
          );
          setHasBeenVisible(true);
        }
      });
    };

    const options = {
      threshold: 0.75,
    };

    const observer = new IntersectionObserver(observerCallback, options);

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [hasBeenVisible, targetRef.current]);

  const [isManager, setIsManager] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      setIsManager(isUserManager(user?.uid));
    }
  }, [user]);

  return (
    <>
      {isManager && !prod && (
        <div className="flex gap-16 px-16">
          <BoxButton
            color={BOXBUTTON_COLOR.BLACK}
            text={'퍼널 정보 삭제'}
            onClick={() => localStorage.removeItem('user-info')}
          ></BoxButton>
          <BoxButton
            color={BOXBUTTON_COLOR.DTRed}
            text={'퍼널이동'}
            onClick={() => router.replace('/personal/funnel')}
          ></BoxButton>
        </div>
      )}
      {adData && (
        <>
          <div className="flex flex-col gap-16 px-16 pt-16" ref={targetRef}>
            <div className="flex items-center justify-between">
              <h1 className="text-new-Section-Title">
                {adData?.location.dongName}{' '}
                <span className="text-new-DTPurple-500">{adData?.academy.tags.mainTag}</span> 학원
                🔥
              </h1>
              <AdvertisementInfoTooltip />
            </div>
            <button
              className="overflow-hidden rounded-[16px]"
              onClick={() => {
                LogEvent.활성화.GAEvent(
                  'click_academyAd_home',
                  createAdLogLabel({ adData: adData, userId: user?.uid || '' }),
                );
                posthog.capture(
                  'click_academyAd_home',
                  createAdLogLabel({ adData: adData, userId: user?.uid || '' }),
                );

                router.push(`/academy/${adData?.academy.academyId}`);
              }}
            >
              <div className="flex h-[160px] w-[100%] flex-col justify-center overflow-hidden">
                <Image
                  src={adData?.academy.image}
                  alt="Academy Image"
                  style={{ width: '100%' }}
                  width={400}
                  height={160}
                />
              </div>
              <div className="bg-new-white-100  flex flex-col gap-8 p-16">
                <div className="flex flex-col gap-4">
                  <div className="flex items-center justify-between">
                    <h2 className="text-new-Body2-bold">{adData?.academy.academyLabel}</h2>
                    <AdvertisementLabel>{adData?.academy.tags.mainTag}</AdvertisementLabel>
                  </div>
                  <div className="flex justify-start gap-4">
                    <div className="flex items-center justify-start gap-2">
                      <Rating
                        variant={'academy'}
                        initialRating={adData?.academy.reviews.totalRating}
                      ></Rating>
                      <div className="text-new-Caption1-medium text-new-gray-500">{`(${adData?.academy.reviews.totalParticipants})`}</div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-4">
                  <div className="flex items-center justify-start gap-4">
                    <div className="text-new-Caption1-medium">
                      {mainProduct ? new BaseLessonCode(mainProduct.code).lessonText : ''}
                    </div>
                    <div className="text-new-Body1-bold">
                      {mainProduct ? mainProduct.totalPrice.toLocaleString('ko-KR') + '원' : ''}
                    </div>
                  </div>
                  <div className="flex justify-start gap-4">
                    {adData?.academy.tags.advantages[0] &&
                      adData?.academy.tags.advantages.map((advantage, index) => (
                        <Label
                          label={advantage || ''}
                          key={`home-ad-${adData?.academy.academyId}-label-${index}`}
                        ></Label>
                      ))}
                  </div>
                </div>
              </div>
            </button>
          </div>
        </>
      )}
    </>
  );
}
